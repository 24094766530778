var addScript = function (scriptContent) {
    var script = document.createElement('script');
    script.innerHTML = scriptContent;
    document.head.appendChild(script);
  };

  var addScriptSource = function (scriptSource, isAsync, isDefer) {
    var script = document.createElement('script');
    script.onload = function () {};
    script.async = isAsync;
    script.defer = isDefer;
    script.src = scriptSource;
    document.head.appendChild(script);
  };