var map;
var markers;


var loadMaps = function (apiKey) {
    addScriptSource("https://maps.googleapis.com/maps/api/js?key=" + apiKey, true, true);
  };

var inicializaMapa = function(lat, lng, zoom, mapType) {
    map = new google.maps.Map(document.getElementById('map'), {
        zoom: zoom,
        center: new google.maps.LatLng(lat, lng),
        mapTypeId: mapType
      });
}

var adicionaMarcadoresMapa = function(marcadores){
    var bounds = new google.maps.LatLngBounds();
    var zIndex = marcadores.length;
    var zoomCustomizado = undefined;
    var center = undefined;

    if(markers){
      markers.forEach(marker => {
        marker.setMap(null);
      });
    }

    markers = [];
    marcadores.forEach(marcador => {
        var latLng = new google.maps.LatLng(parseFloat(marcador.Latitude), parseFloat(marcador.Longitude));
        var marker = new google.maps.Marker({
            position: latLng,
            icon: marcador.Icone,
            optimized: false,
            zIndex:zIndex--,
            map: map
          });
        markers.push(marker);
        bounds.extend(latLng);
        if (marcador.ZoomCustomizado && (marcador.Tipo == 0 || marcador.Tipo == 1 || marcador.Tipo == 3)){
          center = latLng;
          zoomCustomizado = marcador.ZoomCustomizado;
        }
    });

    setTimeout( function() {
      if (!zoomCustomizado)
        map.fitBounds( bounds );
      else
        map.setZoom(zoomCustomizado);
      map.setCenter(zoomCustomizado ? center : bounds.getCenter());
      google.maps.event.trigger(map, 'resize');
    }, 1000 );

}

var limpaMarcadores = function() {
  markers.forEach(marker => {
    marker.setMap(null);
  });
  markers = [];
}

var removeMapa = function() {
    var mapElement = document.getElementById('map');
    if (mapElement)
      mapElement.innerHTML = '';
    map = null;
}
