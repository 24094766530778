var analyticsJS = function (gtagId, dimensions, metrics) {
  var gaContent = generateAnalyticsContent(dimensions, metrics);
  ga(function () {
    //var trackers = ga.getAll();
    var tracker = ga.create(gtagId, 'auto', 't0');
    tracker.set(JSON.parse(gaContent));
    tracker.send('pageview');
  });

  ga(function() {
    ga.remove('t0');
  });
}

var generateAnalyticsContent = function (dimensions, metrics) {
  var gaContent = '{';
  gaContent = generateAnalyticsData(gaContent, dimensions, 'dimension', false);
  gaContent = generateAnalyticsData(gaContent, metrics, 'metric', true);
  gaContent += '}';
  return gaContent;
}

var generateAnalyticsData = function (gaContent, data, fieldName, isNumber) {
  var quote = isNumber ? '' : '"';
  for (var i = 0; i < data.length; i++) {
    if (gaContent != '{')
      gaContent += ', ';

    gaContent += '"' + fieldName + data[i].Index + '": ' + quote + data[i].Value + quote;
  }

  return gaContent;
}

var loadAnalytics = function (gtagId) {
  addGTAG(gtagId);
};

var addGTAG = function (gtagId) {
  addScriptSource("https://www.googletagmanager.com/gtag/js?id=" + gtagId, true, false);

  var scriptContent =
    "window.dataLayer = window.dataLayer || [];" +
    "function gtag(){dataLayer.push(arguments);}" +
    "gtag('js', new Date());" +
    "gtag('config', '" + gtagId + "');";
  addScript(scriptContent);
};


